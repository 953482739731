import React from 'react';
import Layout from "../components/layout";
import { useQuery } from '@apollo/react-hooks';
import Kurs from '../components/kurs';
import {SiteWrapper, SiteContent, Button} from '../components/styles';
import { motion } from 'framer-motion';
import {gql} from 'apollo-boost';
import SEO from '../components/seo';
const KURSE = gql`
{
  kurse(orderBy: reihenfolge_ASC) {
    stage
    titel
    id
    kursbeschreibung {
      html
    }
    ausgebucht
    termine{
      zeit
      ausgebucht
      id
    }
    kursinformationen{
      html
    }
    anmeldung{
      handle
    }
    teammitglieder {
      vorname
      nachname
      id
      teamseite
    }
  }
}`

const Kurse = () =>{
    const {loading, error, data} = useQuery(KURSE)
    if (error) return <Layout layoutImage="kurse"></Layout> 

    else if(loading) return <Layout layoutImage="kurse"></Layout>

    else if(data) return(
      <Layout layoutImage="kurse"> 
       <SiteWrapper>
        <SiteContent> 
        <SEO title="Kurse" />

        <motion.div initial={{opacity: 0}} animate={{opacity: 1}}> 
          <h3 css={{marginBottom: "2em"}}>Kurse und Anmeldung</h3>
          </motion.div>
          <motion.div initial={{opacity: 0}} animate={{opacity: 1}}> 
             <Kurs data={data} />
          </motion.div>
        </SiteContent>
    </SiteWrapper>
    </Layout>

    )
}

export default Kurse