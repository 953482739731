import React from 'react'
import {Link} from 'gatsby';
import {css} from '@emotion/core';
import {FlexContainer, FlexBox, Divider, mq, FlexContainerRow} from './styles'

const Kurs = ( {data:{kurse}}) => {

return(
    <div> 
        {kurse.map(kursInfo => (
         <div key={kursInfo.id} id={kursInfo.id}>  
        {kursInfo.stage == "PUBLISHED" && 
                <FlexContainer direction="column"> 
                 <h3>{kursInfo.titel} </h3>
                <FlexContainerRow  > 
                    <FlexBox direction="column" css={mq({width: ["100%","100%","50%", "50%"], order: 2})}> 
                    <div css={css`background: #FFAD3D; padding: 0.8em;`}> 
                    <h5>Kursleitung</h5>
                    {(kursInfo.teammitglieder) ? kursInfo.teammitglieder.map((mitglied, index) =>{
                        if (mitglied.teamseite === true) return <h6 key={mitglied.id} css={{width: "auto", boxSizing: "auto"}}><Link to={"/team/" + mitglied.vorname.toLowerCase().replace(/\s+/g, '-') + "-" + mitglied.nachname.toLowerCase()}> {mitglied.vorname + " " + mitglied.nachname}</Link></h6>
                        return <h6 key={mitglied.id} css={{width: "auto", boxSizing: "auto"}}>{mitglied.vorname + " " + mitglied.nachname}</h6>
                        } ) : null}
                    {kursInfo.kursinformationen && <div dangerouslySetInnerHTML={{__html: kursInfo.kursinformationen.html}} css={{marginTop: "1em"}} />}
                    </div>
                    {(kursInfo.termine[0]) ? kursInfo.termine.map(termin=> {
                           // let date = new Date(termin);
                           // var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',  hour: 'numeric', minute: 'numeric', };
                           //<p key={index}> {new Intl.DateTimeFormat('de-DE', options).format(date)}</p>
                            return (
                                <div css={css`background: #FFAD3D; margin-top: 1em; padding: 0.8em;`} key={termin.id}> 
                                <p css={css`margin-bottom: 0.4em;`}>{termin.zeit.split('\n').map((item, key) => {
                                    return <span key={key}>{item}<br/></span>
                                })} </p>
                            
                                {termin.ausgebucht ?  <h6 css={css`opacity: 0.7 !important; text-transform: uppercase;`}>ausgebucht</h6> :  kursInfo.anmeldung && <h6> <a target="_blank" href={"https://media.graphcms.com/" + kursInfo.anmeldung.handle}>zur Anmeldung</a> </h6>} 
                                </div> 

                            )
                            })  : <p>gegenwärtig keine Termine</p>}
                
                </FlexBox>
                <FlexBox direction="column" css={mq({width: ["100%","100%","50%", "50%"], marginRight: [0,0,"2em","2em"] })}>
                    <h4>Kursbeschreibung</h4>
                    {kursInfo.kursbeschreibung?.html && <div dangerouslySetInnerHTML={{'__html': kursInfo.kursbeschreibung.html}} / >}
                </FlexBox>
                </FlexContainerRow>
                </FlexContainer>
        }
        <Divider />
        </div> 
        ))
                }
    </div>
)



}

export default Kurs;